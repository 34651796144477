<template>
  <div>
    <div class="header">
      <div class="header_top">
        <img src="../../assets/left.png" alt @click="goBack" />
        <span class="center">企业管理</span>
        <img src="../../assets/right.png" alt />
        <span class="center">企业列表</span>
        <img src="../../assets/right.png" alt />
        <span class="center">{{ title }}</span>
      </div>
    </div>
    <el-tabs v-model="activeName" type="border-card" @tab-click="handleClick">
      <el-tab-pane label="基础字段" name="first">
        <el-form
          label-position="right"
          :model="ruleForm"
          :rules="rules"
          ref="ruleForm"
          class="ruleForm"
          label-width="60px"
          style="text-align: left"
        >
          <el-col :span="12">
            <el-form-item label="企业编号" label-width="110px" prop="code">
              <el-input
                v-model="ruleForm.code"
                :disabled="isShow"
                onkeyup="value=value.replace(/[\u4e00-\u9fa5]/ig,'')"
                minlength="2"
                maxlength="8"
                placeholder="请输入2-8包含字母数字的编码"
              ></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="管理员账号" label-width="110px" prop="account">
              <el-input
                v-model="ruleForm.account"
                :disabled="isShow"
                type="number"
                style="width: 100%"
                placeholder="请输入管理员账号"
              ></el-input>
            </el-form-item>
          </el-col>
          <!-- <el-col :span="12" v-if="!isShow">
            <el-form-item label="管理员密码" label-width="110px" prop="password">
              <el-input
                type="password"
                name="password"
                v-model="ruleForm.password"
                placeholder="请输入管理员密码"
              ></el-input>
            </el-form-item>
          </el-col>-->
          <el-col :span="12">
            <el-form-item label="企业名称" label-width="110px" prop="name">
              <el-input
                v-model="ruleForm.name"
                :disabled="authenticateState === '3'"
                style="width: 100%"
                placeholder="请输入企业名称"
              ></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="统一信用编码" label-width="110px" prop="idCardNumber">
              <el-input
                v-model="ruleForm.idCardNumber"
                :disabled="authenticateState === '3'"
                maxlength="18"
                placeholder="请输入企业统一信用编码"
              ></el-input>
            </el-form-item>
          </el-col>

          <el-col :span="12" v-if="isShow">
            <el-form-item label="用户名" label-width="110px" prop="username">
              <el-input
                v-model="ruleForm.username"
                :disabled="authenticateState === '3'"
                style="width: 100%"
                placeholder="请输入用户名"
              ></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="12" v-if="isShow">
            <el-form-item label="是否社保公司" label-width="110px" prop="isNomal">
              <el-select v-model="ruleForm.isNomal" style="width: 100%" @change="payChange">
                <el-option label="是" :value="true"></el-option>
                <el-option label="否" :value="false"></el-option>
              </el-select>
              <!-- <el-input
                v-model="ruleForm.isNomal"
                :disabled="authenticateState === '3'"
                style="width: 100%"
                placeholder="请输入用户名"
              ></el-input>-->
            </el-form-item>
          </el-col>
          <!-- <el-col :span="12">
            <el-form-item label="联系方式" label-width="110px" prop="userPhone">
              <el-input
                v-model="ruleForm.userPhone"
                :disabled="authenticateState === '3'"
                type="number"
                placeholder="请输入联系方式"
              ></el-input>
            </el-form-item>
          </el-col>-->
          <el-col :span="12" v-if="isShow">
            <el-form-item label="接收通知手机" label-width="110px" prop="smsPhone">
              <el-input
                v-model="ruleForm.smsPhone"
                type="number"
                style="width: 100%"
                placeholder="请输入接收通知手机号"
              ></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="12" v-if="isShow">
            <el-form-item label="接收通知邮箱" label-width="110px" prop="email">
              <el-input v-model="ruleForm.email" type="email" placeholder="请输入接收通知邮箱"></el-input>
            </el-form-item>
          </el-col>

          <el-button
            type="primary"
            v-if="!isShow"
            style="float: right"
            @click="onSubmit('ruleForm')"
          >保存</el-button>
          <el-button type="primary" v-else style="float: right" @click="goSubmit('ruleForm')">保存</el-button>
        </el-form>
      </el-tab-pane>
      <el-tab-pane label="微信配置" name="second" v-if="isShow">
        <Weixin :code="code" :userInfo="userInfo.config"></Weixin>
      </el-tab-pane>
      <el-tab-pane label="页面个性化配置" name="third" v-if="isShow" lazy>
        <Background :code="code" :userInfo="userInfo.config" />
      </el-tab-pane>
      <el-tab-pane label="计费信息" name="fourth" v-if="isShow" lazy>
        <Charging :code="code" :userInfo="userInfo.config" />
      </el-tab-pane>
      <el-tab-pane label="支付信息" name="fiveth" v-if="isShow" lazy>
        <Pay :code="code" :userInfo="userInfo.config" />
      </el-tab-pane>
      <!-- <el-tab-pane label="账户信息" name="six" v-if="isShow" lazy>
      <Account :code="code" :userInfo="userInfo.config" />
      </el-tab-pane>-->
    </el-tabs>
  </div>
</template>
<script>
// import baseUrl from '../../config/config';
import { api } from '../../api/base/index';
import { enterpriseReg, phoneReg } from '../../../src/lib/global.js';

export const customerAdd = api()('enterprise.add.json');
export const customerInfo = api()('enterprise.info.json');
export const customerEdit = api()('enterprise.config.edit.json');
export default {
  components: {
    Weixin: () => import('./weixin.vue'),
    Background: () => import('./background.vue'),
    Charging: () => import('./charging.vue'),
    Pay: () => import('./pay.vue'),
    // Account: () => import('./AccountInfo.vue')
  },
  data () {
    return {
      activeName: 'first',
      authenticateState: '',
      code: '',
      fileList: [],
      title: '',
      isShow: false,
      userInfo: {},
      action: '',
      ruleForm: {
        account: '',
        name: '',
        idCardNumber: '',
        logoUrl: '',
        username: '',
        isNomal: '',
        smsPhone: '',
        email: '',
        code: '',
      },
      rules: {
        code: [
          { required: true, message: '请输入企业编号', trigger: 'blur' },
          { pattern: enterpriseReg, message: '请输入2-8位企业编号' },
        ],
        isNomal: [
          { required: true, message: '请选择是否为社保公司', trigger: 'blur' },
        ],
        account: [
          { required: true, message: '请输入管理员账号', trigger: 'blur' },
          { pattern: phoneReg, message: '请输入正确的手机号' },
        ],
        name: [{ required: true, message: '请输入企业名称', trigger: 'blur' }],
        idCardNumber: [
          { required: true, message: '请输入企业信用代码', trigger: 'blur' },
          {
            pattern: /^[^_IOZSVa-z\W]{2}\d{6}[^_OZSVa-z\W]{10}$/g,
            message: '请输入正确企业统一信用代码',
          },
        ],
        logoUrl: [
          { required: true, message: '请添加企业Logo', trigger: 'blur' },
        ],
        username: [
          { required: true, message: '请输入用户名', trigger: 'blur' },
        ],
        // userPhone: [{ required: true, message: '请输入联系人电话', trigger: 'blur' },
        // { pattern: phoneReg, message: '请输入正确的手机号' }],
        // smsPhone: [{ required: true, message: '请输入接收通知电话', trigger: 'blur' }],
        // email: []
      },
    };
  },
  async created () {
    this.action = window.location.origin + '/enterprise.upload.json';
    this.code = this.$route.query.code;
    if (this.code) {
      this.title = '编辑企业';
      await this.logoData();
      this.isShow = true;
    } else {
      this.title = '添加企业';
      this.isShow = false;
    }
  },
  methods: {
    payChange (e) {
      console.log(e);
    },
    handleClick (tab, event) {
      console.log(tab, event);
    },
    goBack () {
      window.history.go(-1);
    },
    async logoData () {
      const params = {
        code: this.code,
      };
      const res = await customerInfo(params);
      this.userInfo = res;
      this.authenticateState = this.userInfo.authenticateState;
      this.ruleForm.code = this.userInfo.config.character;
      (this.ruleForm.idCardNumber = this.userInfo.idCardNumber),
        (this.ruleForm.name = this.userInfo.name),
        (this.ruleForm.isNomal = this.userInfo.config.isNomal),
        (this.ruleForm.account = this.userInfo.config.userPhone),
        // this.ruleForm.userPhone = this.userInfo.config.userPhone,
        (this.ruleForm.username = this.userInfo.config.username);
      this.ruleForm.smsPhone = this.userInfo.config.smsPhone;
      this.ruleForm.email = this.userInfo.config.email;
      console.log(this.ruleForm);
      // Object.keys(this.ruleForm).forEach(it => {
      //   this.ruleForm[it] = res[it]

      // })
    },
    close (formName) {
      this.$refs[formName].resetFields();
    },
    onSubmit (formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          customerAdd({
            code: this.ruleForm.code,

            idCardNumber: this.ruleForm.idCardNumber,

            name: this.ruleForm.name,
            account: this.ruleForm.account,
            // smsPhone: this.ruleForm.smsPhone,
            // email: this.ruleForm.email,
            username: this.ruleForm.username,
          }).then(() => {
            this.close(formName);
            window.history.back(-1);
          });
        } else {
          return false;
        }
      });
    },
    goSubmit (formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          console.log(this.ruleForm, '321');
          customerEdit({
            isNomal: this.ruleForm.isNomal,
            idCardNumber: this.ruleForm.idCardNumber,
            name: this.ruleForm.name,
            // userPhone: this.ruleForm.userPhone,
            username: this.ruleForm.username,
            smsPhone: this.ruleForm.smsPhone,
            email: this.ruleForm.email,
            code: this.code,
          }).then(() => {
            // this.close(formName);
            // window.history.back(-1);
            this.$message({
              message: '保存成功',
              type: 'success',
            });
          });
        } else {
          return false;
        }
      });
    },
    beforeAvatarUpload (file) {
      console.log(file);
    },
    successFile (res, fileList) {
      console.log(fileList);
      this.ruleForm.logoUrl = res.data;
      console.log(this.ruleForm.logoUrl);
      this.filePath = res.data;
    },
  },
};
</script>
<style lang="scss" scope>
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
}
input[type="number"] {
  -moz-appearance: textfield;
}
</style>
